import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';

import './App.scss'
import { Button, Container, TextField, Box, Typography, Divider, AppBar } from '@mui/material';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import axios from 'axios';



const App = () => {
  const [files, setFiles] = useState([])
  const [name, setName] = useState('')
  const [errorName, setErrorName] = useState(false)
  const [linki, setLinki] = useState([])
  const [actGalery, setActGallery] = useState(0)
  const getUploadParams = ({ meta }) => { return { url: 'https://slub.tnfe.pl/uploader.php?name=' + name } }
  const handleChangeStatus = file => {

    let { status, id } = file.meta
    if (status === 'ready') {
      setFiles([...files, file])
      return
    }
    if (status === 'removed') {
      const filesTmp = [...files]
      filesTmp.splice(filesTmp.findIndex(f => f.meta.id === id), 1)
      setFiles(filesTmp)
      return
    }
    if (status === 'done') {
      setTimeout(() => {
        file.remove()
      }, 1000)

    }


  }
  const wysliWszystkie = () => {
    if (name.length === 0) {
      console.log(files)
      setErrorName(true)
      return
    }
    files.forEach(f => {
      f.restart()
    })

  }
  const setNameHandler = e => {
    setErrorName(false)
    setName(e.target.value)
  }
  useEffect(() => {
    if (files.length === 0) {
      axios.get("https://slub.tnfe.pl/files/images.json?" + Date.now())
        .then(resp => {
          setLinki(resp.data.sort((a, b) => {
            a.files.sort((a, b) => {
              if (a.substr(10) > b.substr(10)) return 1
              if (b.substr(10) > a.substr(10)) return -1
              return 0
            })
            b.files.sort((a, b) => {
              if (a.substr(10) > b.substr(10)) return 1
              if (b.substr(10) > a.substr(10)) return -1
              return 0
            })
            return b.files.length - a.files.length
          }))
        })
      console.log("pobieram linki")
    }

  }, [files])

  return (

    <Box className="box" sx={{ bgcolor: 'background.paper', width: "100%" }}>

      <AppBar position="static" className="appbar">
        <Container>
          <Typography variant="h4" >
            Zdjęcia ślubne Oli i Michała
          </Typography>
        </Container>
      </AppBar>
      <Container className="content">
        <Box>
          <Typography variant="h4"  >
            Dodaj zdjęcia
          </Typography>


          <Dropzone classNames={"dropzone"}
            getUploadParams={getUploadParams}
            autoUpload={false}
            canRestart={false}
            onChangeStatus={handleChangeStatus}

            inputWithFilesContent="Dodaj więcej"
            accept="image/*"
            inputContent="Przeciągnij lub klinij żeby dodać"
          />

          <Box className="senderBox">
            <TextField error={errorName} className={'input'} onInput={setNameHandler} id="standard-basic" label="Kto wysyła?" variant="standard" />
            <Button className="sendBtn" variant="contained" onClick={wysliWszystkie}>Wyślij wszystkie</Button>
          </Box>



        </Box>
        <Box>
          <Divider />
          <Typography variant="h4" >
            Galerie
          </Typography>
          <div className="gallerySelection">
            <div className="scrool" style={{ minWidth: linki.length * 140 }}>
              {
                linki.map(({ name, files }, i) => {
                  return <div onClick={() => setActGallery(i)} className={i === actGalery ? 'active' : null} key={i} style={{ backgroundImage: `url("https://slub.tnfe.pl/files/thumb/${files[0]}")` }}>
                    <span>{name} ({files.length})</span>
                  </div>
                })
              }
            </div>
          </div>



          {linki.length > 0 ?
            <div >
              <div className="label">Autor - {linki[actGalery].name}</div>

              <ImageGallery lazyLoad={true} items={linki[actGalery].files.map(it => {
                return { "original": `https://slub.tnfe.pl/files/${it}`, "thumbnail": `https://slub.tnfe.pl/files/thumb/${it}` }
              })} showThumbnails={true} />



            </div>
            : null}
        </Box>
      </Container>
    </Box>

  );
}

export default App;